import React from 'react';
import './index.css';

const Error = () => {

    return (

        <h1>Error</h1>

    );
}

export default Error;