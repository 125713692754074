import React from 'react';
import './index.css';

const Contact = () => {

    return (
        <>
        <h1>Contact</h1>
        <div className='solid'>
   
    <h4 className="txt">Numar de telefon:</h4>
    <h4 className='txt'>-</h4>
    <br />
 
    <h4 className='txt'>Email:</h4>
    <h4 className='txt'>office@inodigitalsig.ro</h4>
    <br />
    
    <h4 className='txt'>Adresa:</h4>
    <h4 className='txt'>Adresa</h4>

</div>
 <div className='notSolid'></div>

        
        </>
    );  
}

export default Contact;