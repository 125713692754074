import React from 'react'

export const Footer = () => {
    return (
       <div className='footer'>
        <footer>© InoDigitalSig 2020 All rights reserved. </footer>
        
</div>
    )
}

export default  Footer;